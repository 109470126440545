/** Pinepointers 6: Social Worker **/

import React from "react";
import { preloadImages, scaleFullscreen, showText, hideText, positionText, resetAnimationDelay, loadVideo } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Circ } from "gsap";

let globalState, data, preloadData;

class PinepointersPage6 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[6];
        preloadData = globalState.assets[7];

        //refs
        this.fullscreenBg = null;

    }

    state = {
        textVisible: false,
    }




    componentDidMount = () => {

        setTimeout(() => {
            //1. play video
            this.refs.bgVideo1Ref.play();
            this.refs.bgVideo2Ref.play();
            this.refs.bgVideo3Ref.play();

            //2. animate highlighter
            //gsap.to(this.refs.highlighterWrapperRef, 1, { scale: 1, ease: Circ.easeOut, delay: 0.5 });

            //3. Animate in Badge
            gsap.to(this.refs.socialWorkerBadgeRef, 0.75, { x: 0, y: 0, rotate: 0, ease: Circ.easeOut, delay: 1 });

        }, globalState.transitionDuration);


        //position text
        positionText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

        //add base track
        globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2);

        //dim main base track
        //globalState.baseAudioRef.updateVolume(1, 70);

        //Update dimensions
        this.updateDimensions();

        //Add resize listener
        window.addEventListener("resize", this.updateDimensions);

        //Preload assets from next page
        preloadImages([
            globalState.cdnUrl + preloadData.text1, 
            globalState.cdnUrl + preloadData.badge,
            globalState.cdnUrl + preloadData.badgeTop, 
            globalState.cdnUrl + preloadData.background,
            globalState.cdnUrl + preloadData.bgVideoPoster1,
            globalState.cdnUrl + preloadData.bgVideoPoster2,
            globalState.cdnUrl + preloadData.bgVideoPoster3
        ], this.preloadCallback);

        //Hls is added in gatsby-ssr.js
        let videoStream1 = globalState.videoUrl + data.bgVideo1;
        let videoStream2 = globalState.videoUrl + data.bgVideo2;
        let videoStream3 = globalState.videoUrl + data.bgVideo3;
        
        loadVideo(this.refs.bgVideo1Ref, videoStream1);
        loadVideo(this.refs.bgVideo2Ref, videoStream2);
        loadVideo(this.refs.bgVideo3Ref, videoStream3);

    }

    preloadCallback = () => {
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }



    componentWillUnmount() {
        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);

        window.removeEventListener("resize", this.updateDimensions);
    }


    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "right", "top", globalState.textPosition.rightTop);


        } else {
            //hide text
            hideText(this.refs.text1Ref, "right");

        }
    }

    isTransitioning = () => {
        this.refs.bgVideo1Ref.pause();
        this.refs.bgVideo2Ref.pause();
        this.refs.bgVideo3Ref.pause();
        //pause animations
    }

    updateDimensions = () => {
        console.log('updateDimensions');
        //background native size is 1288 x 724
        scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 724, false);

        

    }

    render() {

        return (

            <>

                <link rel="prefetch" href="/pinepointers/7" />
                {preloadData
                    && (
                        <>
                        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo1} />
                        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo2} />
                        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo3} />
                        </>
                    )
                }    

                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="/pinepointers/7" prevLocation="/pinepointers/5"
                    isTransitioning={this.isTransitioning} />
                {data
                    && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - Pinepointers" />

                    <div className="text-wrapper">
                        <div className="text text--right-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "8vw", marginTop: "15vh" }} onClick={this.toggleTextDisplay} alt="Kim went on to fulfill her yearbook prophecy. Ambition – To be a social worker. She still sings, though the costumes are archived in a tickle trunk in her basement. Her recollections of Pine Point are less quick to surface, but when they do, they’re mostly happy." />
                        </div>
                    </div>

                    <div className="fullscreen-bg pinepointers-bg" ref="fullscreenBgRef">
                        <video ref="bgVideo1Ref" poster={globalState.cdnUrl + data.bgVideoPoster1} autoPlay={false} loop={true} muted={true} playsInline className="video" style={{ width: '1016px', height: '725px', top: 0, left: '-1px', position: 'absolute', zIndex: -1 }}>
                            <track kind="captions" srcLang="en" />
                        </video>
                        <video ref="bgVideo2Ref" poster={globalState.cdnUrl + data.bgVideoPoster2} autoPlay={false} loop={true} muted={true} playsInline className="video" style={{ width: '526px', height: '296px', top: '430px', left: '-1px', position: 'absolute', zIndex: -1 }}>
                            <track kind="captions" srcLang="en" />
                        </video>
                        <video ref="bgVideo3Ref" poster={globalState.cdnUrl + data.bgVideoPoster3} autoPlay={false} loop={true} muted={true} playsInline className="video" style={{ width: '526px', height: '298px', top: '436px', left: '762px', position: 'absolute', zIndex: -1 }}>
                            <track kind="captions" srcLang="en" />
                        </video>
                        <img src={globalState.cdnUrl + data.background} alt="background" className="background" />
                        <img src={globalState.cdnUrl + data.badge} className="badge" alt="The Beauty" style={{ left: '266px', top: '98px' }} />
                        <img src={globalState.cdnUrl + data.badgeTop} className="badge socialWorkerBadge" ref="socialWorkerBadgeRef" alt="The Social Worker" style={{ left: '300px', top: '248px' }} />
                    </div>
                </div>
                    )
                }

            </>
        );
    }


};

export default PinepointersPage6
